import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { portfolioData } from '../../config/dataApi';
import * as filters from './constants';
import Card from '../../components/Cards';
import usePortfolioFilter from '../../hooks/usePortfolioFilter';
import useStyles from './styles';

function Publications() {
  const { filteredPortfolio, filter, setFilter, loading } = usePortfolioFilter();
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setFilter(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom align="center">
          {portfolioData.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {portfolioData.description}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs id="tab-filter" value={filter} onChange={handleChange} aria-label="porfolio filter" centered>
          <Tab value={filters.POSTDOC_PUB_FILTER} label="2021-2023" selected={true} />
          <Tab value={filters.MASTERS_PUB_FILTER} label="2017-2020" />
          <Tab value={filters.ALL_FILTER} label="All" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={4}>
          {loading ? (
            <>
              <Grid item>
                <Skeleton variant="rect" width={300} height={350} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width={300} height={350} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width={300} height={350} />
              </Grid>
            </>
          ) : (
            filteredPortfolio.map((portfolioItem) => (
              <Grid key={portfolioItem.id} item>
                <div className={classes.root}>
                  <Card {...portfolioItem} />
                </div>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Publications;
