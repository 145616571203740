import React from 'react';

import VideoBackground from '../../components/VideoBackground';
import PersonalData from '../../components/PersonalData';
import Publications from '../Publications';
import Contact from '../Contact';

function Home() {
  return (
    <VideoBackground>
      <PersonalData />
      <Publications />
      <Contact />
    </VideoBackground>
  );
}

export default Home;
