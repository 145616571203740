import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Publications from './pages/Publications';
import Contact from './pages/Contact';

function Routes() {
  return (
    <Switch>
      <Route path="/publications">
        <Publications />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default Routes;
