import { useState, useEffect } from 'react';

import { portfolio } from '../config/dataApi';
import * as filters from '../pages/Publications/constants';
import * as types from '../components/Cards/constants';

export const handleFilter = (items, filter) =>
  items.filter((item) => {
    if (filter === filters.POSTDOC_PUB_FILTER) {
      return item.type === types.POSTDOC_PUB_TYPE;
    }
    if (filter === filters.MASTERS_PUB_FILTER) {
      return item.type === types.MASTERS_PUB_TYPE;
    }
    return true;
  });

const handleOrder = (items) =>
  items.sort(function (a, b) {
    if (a.id > b.id) {
      return 1;
    }
    return -1;
  });

function usePortfolioFilter() {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(filters.POSTDOC_PUB_FILTER);
  const [filteredPortfolio, setFilteredPortfolio] = useState([]);

  useEffect(() => {
    setFilteredPortfolio(handleFilter(portfolio, filter));
    setFilteredPortfolio((prevState) => handleOrder(prevState));
    setLoading(false);
  }, [filter]);

  return { filteredPortfolio, filter, setFilter, loading };
}

export default usePortfolioFilter;
