import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { contactData } from '../../config/dataApi';
import * as filters from './constants';
import Card from '../../components/Cards';
import usePortfolioFilter from '../../hooks/usePortfolioFilter';
import Link from '@material-ui/core/Link';

import { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { personalData } from '../../config/dataApi';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contact() {
  const { filteredPortfolio, filter, setFilter, loading } = usePortfolioFilter();
  const { socialMedias, email } = personalData;

  const handleChange = (event, newValue) => {
    setFilter(newValue);
  };

  //https://github.com/A-Birch/mk-contact-form/blob/master/src/components/ContactForm.jsx
  return (
    <Grid container justify="center" spacing="8">
      <Grid item xs={8} style={{ marginBottom: '-80px' }}>
        <Typography variant="h2" gutterBottom align="center">
          {contactData.title}
        </Typography>
      </Grid>
      <Grid container justify="center" style={{ marginTop: 30 }}>
        {socialMedias.map((socialMedia) => (
          <IconButton
            key={socialMedia.name}
            aria-label={socialMedia.name}
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={socialMedia.url}
          >
            {/* { ToDo - Add flask and frontiers link} */}
            <FontAwesomeIcon icon={[socialMedia.iconType, socialMedia.name]} />
          </IconButton>
        ))}
        <IconButton aria-label="email" component="a" href={`mailto:${email}`}>
          <FontAwesomeIcon icon={faEnvelope} />
        </IconButton>
      </Grid>
      <Grid item style={{ width: '700px' }}>
        <form id="contact-form" className={'2'}>
          <TextField fullWidth required id="name" label="Name" name="userName" className={'3'} margin="normal" />
          <TextField fullWidth required id="email" label="Email" name="email" className={'4'} margin="normal" />
          <TextField
            fullWidth
            required
            id="message"
            label="Message"
            name="message"
            className={'5'}
            margin="normal"
            multiline
            rowsMax="4"
          />
          <Grid container justify="center" spacing={2} style={{ marginTop: 20 }}>
            <Grid item>
              <Button
                className={'6'}
                type="reset"
                variant="contained"
                color="default"
                //disabled={submitting || pristine}
              >
                RESET
              </Button>
            </Grid>
            <Grid item>
              <Button className={'7'} type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Contact;
