import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faCode } from '@fortawesome/free-solid-svg-icons';

import useStyles from './styles';
import * as types from './constants';

function Cards({ name, description, type, details, url }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea href={url}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
          <Typography variant="body2" component="p">
            {details}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

Cards.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.oneOf([types.POSTDOC_PUB_TYPE, types.MASTERS_PUB_TYPE]).isRequired,
};

export default Cards;
