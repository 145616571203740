import defaultprofilepic from '../assets/images/headshot.jpg';
import * as portfolioTypes from '../components/Cards/constants';

export const personalData = {
  name: 'Dr. Jaya Lakshmi Thangaraj',
  degree: 'Ph.D.',
  description: 'Cancer Immunology, Immunotherapy, NK/CAR NK/T cells, iPSCs and ES cells',
  about:
    'I am a cancer immunotherapy scientist, optimistic to develop novel CAR NK/T cell based cancer immunotherapies. Currently working with engineered iPSC derived NK cells to improve their anti-tumor activity. Having expertise in cancer immunology and skillful in demonstrating in vitro as well as in vivo preclinical studies with CAR NK /T cells, Macrophages/ CAR macrophages and Dendritic cells. I am a Review Editor in Frontiers in Immunology and Frontiers in Oncology Editorial board on the section of Cancer Immunity and Immunotherapy. I am happy to share my experience in collaboration/co-founder opportunities related to cancer immunology and immunotherapy.',
  profilePicture: defaultprofilepic,
  socialMedias: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jaya-lakshmi-thangaraj-5ba8b0216/',
      iconType: 'fab',
    },
    {
      name: 'google',
      url: 'https://scholar.google.com/citations?user=MoODmm0AAAAJ&hl=en',
      iconType: 'fab',
    },
    {
      name: 'researchgate',
      url: 'https://www.researchgate.net/profile/Jaya-Thangaraj',
      iconType: 'fab',
    },
    // {
    //   name: 'building-columns',
    //   url: 'https://regenmed.ucsd.edu/research/kaufman-lab/people.html',
    //   iconType: 'fab',
    // },
    // {
    //   name: 'frontiers',
    //   url: 'https://loop.frontiersin.org/people/572744/overview',
    // },
  ],
};

export const menuItems = [
  {
    to: '/',
    primary: 'Home',
  },
  {
    to: '/publications',
    primary: 'PUBLICATIONS',
  },
  {
    to: '/contact',
    primary: 'CONTACT',
  },
];

export const portfolioData = {
  title: 'Publications',
  description: '',
};

export const contactData = {
  title: 'Get in touch',
  description: 'Get in touch',
};

const postDocPublications = [
  {
    id: 101,
    name:
      'Expanded natural killer cells potentiate the antimyeloma activity of daratumumab, lenalidomide, and dexamethasone in a myeloma xenograft model',
    description: '📜 Cancer Immunology, Immunotherapy · Nov 16, 2022',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details:
      '🏛️National Research Foundation of Korea, 2018R1A5A2024181, Ministry of Education, Science and Technology, 2021R1A2B5B0100214911.',
    url: 'https://link.springer.com/article/10.1007/s00262-022-03322-1',
  },
  {
    id: 102,
    name:
      'A novel method for clinical scale production of natural killer cells from clonal master induced pluripotent stem cells with CISH knockout for next generation, off-the-shelf cancer immunotherapy',
    description: '📜 Proceedings of the American Association for Cancer Research Annual Meeting 2023',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '💻 ISSN: 1538-7445',
    url: 'https://aacrjournals.org/cancerres/article/83/7_Supplement/2919/720783',
  },
  {
    id: 103,
    name:
      'Natural killer cell therapy potentially enhances the antitumor effects of bevacizumab plus irinotecan in a glioblastoma mouse model',
    description: '📜 Frontiers in Immunology · Jan 10, 2023',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '🏛️Grant Number: HCRI19011, Chonnam National University Hwasun Hospital Institute for Biomedical Science',
    url: 'https://www.frontiersin.org/articles/10.3389/fimmu.2022.1009484/full',
  },
  {
    id: 104,
    name:
      'Targeting hematological malignancies and solid tumors with switchable chimeric antigen receptor-engineered iPSC-derived natural killer cells',
    description: '📜 Cancer Research · June 15, 2022',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '💻 ISSN: 1538-7445',
    url: 'https://aacrjournals.org/cancerres/article/82/12_Supplement/559/701328',
  },
  {
    id: 105,
    name: 'Novel IL-15 dendritic cells have a potent immunomodulatory effect in immunotherapy of multiple myeloma',
    description: '📜 Translational Oncology · June 1, 2022',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details:
      '🏛️National Research Foundation of Korea (NRF) grant funded by the Korea government (NRF-2020R1A5A2031185, NRF-2020R1A2C2010098)',
    url: 'https://www.sciencedirect.com/science/article/pii/S1936523322000754',
  },
  {
    id: 106,
    name:
      'Expanded natural killer cells with daratumumab, lenalidomide and dexamethasone combination potentiates antimyeloma activity in a myeloma xenograft model',
    description: '📜 Clinical Lymphoma Myeloma and Leukemia · October 1, 2021',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '📚 Cancer Immunology, Immunotherapy volume 72, pages1233–1246 (2023)',
    url: 'https://www.sciencedirect.com/science/article/abs/pii/S2152265021021406',
  },
  {
    id: 107,
    name:
      'Expansion of cytotoxic natural killer cells in multiple myeloma patients using K562 cells expressing OX40 ligand and membrane-bound IL-18 and IL-21',
    description: '📜 Cancer Immunology, Immunotherapy · June 20, 2021',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details:
      '🏛️National Research Foundation of Korea (NRF), funded by the Ministry of Education, Science and Technology (2018R1A2B6006200, 2020R1A2C2010098)',
    url: 'https://link.springer.com/article/10.1007/s00262-021-02982-9',
  },
  {
    id: 108,
    name:
      'Expanded natural killer cells augment the antimyeloma effect of daratumumab, bortezomib, and dexamethasone in a mouse model',
    description: '📜 Cellular & Molecular Immunology · July 2021',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '🖥️ ISSN: 2042-0226',
    url: 'https://www.nature.com/articles/s41423-021-00686-9',
  },
  {
    id: 109,
    name:
      'A combination of immunoadjuvant nanocomplexes and dendritic cell vaccines in the presence of immune checkpoint blockade for effective cancer immunotherapy',
    description: '📜 Cellular & Molecular Immunology · June 2021',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details: '🖥️ ISSN: 2042-0226',
    url: 'https://www.nature.com/articles/s41423-021-00666-z',
  },
  {
    id: 110,
    name:
      'Potent anti-myeloma efficacy of dendritic cell therapy in combination with pomalidomide and programmed death-ligand 1 blockade in a preclinical model of multiple myeloma',
    description: '📜 Cancer Immunology, Immunotherapy · January 2021',
    type: portfolioTypes.POSTDOC_PUB_TYPE,
    details:
      '🏛️National Research Foundation of Korea (NRF), funded by the Ministry of Education, Science, and Technology (2018R1A5A2024181, NRF-2018R1C1B5041536, NRF-2020R1A2C2010098)',
    url: 'https://link.springer.com/article/10.1007/s00262-020-02654-0',
  },
];

const mastersPublications = [
  {
    id: 201,
    name:
      'A Combination Therapy with Dendritic Cells, Pomalidomide and Programmed Death-Ligand 1 Blockade Exerts a Potent Antitumor Immunity in a Murine Model of Multiple Myeloma',
    description: '📜 Blood | American Society of Hematology · Nov 13, 2019',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details: '📚 Volume 134, Issue Supplement_1',
    url:
      'https://ashpublications.org/blood/article/134/Supplement_1/1819/427746/A-Combination-Therapy-with-Dendritic-Cells',
  },
  {
    id: 202,
    name: 'Cellular immunotherapy in multiple myeloma',
    description: '📜 The Korean journal of internal medicine · September 2019',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details:
      '🏛️ National Research Foundation of Korea (NRF), funded by the Korea government (MSIT), grants (NRF-2018R1C1B5041536, 2018R1A5A2024181)',
    url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6718748/',
  },
  {
    id: 203,
    name: 'PS1357 FITNESS SIGNATURES IN MULTIPLE MYELOMA PROGRESSION AND RESISTANCE',
    description: '📜 HemaSphere, Immunotherapy · June 1, 2019',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details:
      '🏛️ National Research Foundation of Korea, 2018R1A5A2024181, Ministry of Education, Science and Technology, 2021R1A2B5B0100214911.',
    url:
      'https://journals.lww.com/hemasphere/Abstract/2019/06001/PS1357_FITNESS_SIGNATURES_IN_MULTIPLE_MYELOMA.1242.aspx',
  },
  {
    id: 204,
    name:
      'Enhancement of Antitumor Immunity Using Dendritic Cells Combined with Lenalidomide and Programmed Death Ligand-1 Blockade in Multiple Myeloma Mouse Model',
    description: '📜 Blood | American Society of Hematology · Nov 29, 2018',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details: '📚 Volume 132, Issue Supplement 1',
    url:
      'https://ashpublications.org/blood/article/132/Supplement%201/3194/263531/Enhancement-of-Antitumor-Immunity-Using-Dendritic',
  },
  {
    id: 205,
    name: 'Synergistic antimyeloma activity of dendritic cells and pomalidomide in a murine myeloma model',
    description: '📜 Frontiers in Immunology · August 3, 2018',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details:
      '🏛️ National Research Foundation of Korea (NRF), funded by the Ministry of Education, Science, and Technology. Grants (2015R1D1A1A09057809, 2018R1A5A2024181, NRF-2018R1C1B5041536)',
    url: 'https://www.frontiersin.org/articles/10.3389/fimmu.2018.01798/full',
  },
  {
    id: 206,
    name:
      'Lenalidomide and programmed death-1 blockade synergistically enhances the effects of dendritic cell vaccination in a model of murine myeloma',
    description: '📜 Frontiers in Immunology · June 18, 2018',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details:
      '🏛️ National Research Foundation of Korea, 2018R1A5A2024181, Ministry of Education, Science and Technology',
    url: 'https://www.frontiersin.org/articles/10.3389/fimmu.2018.01370/full',
  },
  {
    id: 207,
    name:
      'Synergistic anti-myeloma activity by combination of dendritic cells, pomalidomide and dexamethasone in a murine myeloma model',
    description: '📜 EUROPEAN JOURNAL OF IMMUNOLOGY · May 01, 2018',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details:
      '🏛️ National Research Foundation of Korea, 2018R1A5A2024181, Ministry of Education, Science and Technology, Grants (2015R1D1A1A09057809, 2018R1A5A2024181, NRF-2018R1C1B5041536)',
    url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6085413/',
  },
  {
    id: 208,
    name:
      'Expanded natural killer cells potentiate the antimyeloma activity of daratumumab, lenalidomide, and dexamethasone in a myeloma xenograft model',
    description: '📜 Blood | American Society of Hematology · May 01, 2018',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details: '📚 Volume 130, Supplement 1',
    url: 'https://www.sciencedirect.com/science/article/pii/S0006497119859712',
  },
  {
    id: 209,
    name:
      'Cytoplasmic Transduction Peptide-Fused Recombinant Tumor-Associated Antigens Elicit Potent Myeloma-Specific Cytotoxic T Lymphocytes By Loading Onto Dendritic Cells: Implications of Feasible Tumor Antigens for Clinical Application Against Multiple Myeloma',
    description: '📜 Blood | American Society of Hematology · Dec 07, 2017',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details: '📚 Volume 130, Supplement 1',
    url: 'https://www.sciencedirect.com/science/article/pii/S0006497119859712',
  },
  {
    id: 210,
    name:
      'Multimodality Targeting of Dendritic Cell Vaccination Combined with Immunomodulatory Drug and Immune Checkpoint Blockade Has Synergistically Induced a Marked Tumor Regression By Modulating Tumor Microenvironment in a Murine Myeloma Model',
    description: '📜 Blood | American Society of Hematology · Dec 07, 2017',
    type: portfolioTypes.MASTERS_PUB_TYPE,
    details: '📚 Volume 130, Issue Supplement 1',
    url: 'https://ashpublications.org/blood/article/130/Supplement%201/5453/115783',
  },
];

export const portfolio = [...postDocPublications, ...mastersPublications];
