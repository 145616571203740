import React from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { personalData } from '../../config/dataApi';
import useStyles from './styles';
import ButtonLink from '../ButtonLink';
import Typist from 'react-typist';
import Button from '@material-ui/core/Button';

function PersonalData() {
  const classes = useStyles();

  const { name, degree, description, about, profilePicture, socialMedias, email } = personalData;

  return (
    <div className={classes.root}>
      <Avatar alt="Profile picture" src={profilePicture} className={classes.avatar} />
      <Grid container className={classes.text}>
        <Grid item xs={12}>
          <Typography variant="h3">{`${name} ${degree}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <Typist>{description}</Typist>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{about}</Typography>
        </Grid>
        <Grid item xs={12}>
          {socialMedias.map((socialMedia) => (
            <IconButton
              key={socialMedia.name}
              aria-label={socialMedia.name}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={socialMedia.url}
            >
              {/* { ToDo - Add flask and frontiers link} */}
              <FontAwesomeIcon icon={[socialMedia.iconType, socialMedia.name]} />
            </IconButton>
          ))}
          <IconButton aria-label="email" component="a" href={`mailto:${email}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Button className={'7'} type="submit" variant="contained" color="primary">
            Contact Me
          </Button>
          {/* <ButtonLink to={'/contact'} primary={'Contact me'} /> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonalData;
